import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parser from "html-react-parser";
import MyEditor from "./items/MyEditor";
import PendingBlog from "./items/PendingBlog";
import React from "react";
import app from "./firebase/FirebaseConfig";
import { getDatabase, ref, get, update, remove } from "firebase/database";
import config from "./firebase/Config";

function Edit({ posttype }) {
  // console.log(posttype);
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [isPending, setIsPending] = useState(true);
  const [coverImg, setCoverImg] = useState("");
  const [putError, setPutError] = useState(false);
  const [putPending, setPutPending] = useState(false);
  const [error, setError] = useState(false);
  const [asProject, setAsProject] = useState(false);

  const navigate = useNavigate();
  const url = `${config.db_path}/content/${id}`;

  const handleReq = async () => {
    const db = getDatabase(app);
    const dbRef = ref(db, url);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const jsonVal = snapshot.toJSON();
      setBody(jsonVal.body);
      setTitle(jsonVal.title);
      setAuthor(jsonVal.author);
      setDate(jsonVal.date);
      setDescription(jsonVal.description);
      setCoverImg(jsonVal.cover);

      if (jsonVal.type == "blog") {
        setAsProject(false);
      } else if (jsonVal.type == "project") {
        setAsProject(true);
      }
      setIsPending(false);
    } else {
      setIsPending(false);
      setError("Could not fetch data from that resource");
    }
  };

  useEffect(() => {
    setIsPending(true);
    handleReq();
  }, [url]);

  const handleSubmit = async (e) => {
    let type = "blog";
    e.preventDefault();
    // setPutPending(true);

    const submitter = e.nativeEvent.submitter;
    if (submitter && submitter.id === "MyEditorSubmitBtn") {
      if (asProject) {
        type = "project";
      } else {
        type = "blog";
      }
      console.log("type", type);
      console.log("posttype", posttype);
      // const blog = { title, body: body, author };
      const db = getDatabase(app);

      if (type !== posttype) {
        const dbRef = ref(db, `${config.db_path}/${posttype}s/${id}`);
        const contentRef = ref(db, `${config.db_path}/content/${id}`);
        await remove(dbRef);
        await remove(contentRef);
        // const setdbRef = ref(db, `${config.db_path}/${type}s/${id}`);
        // await set()
      }
      const dbRef = ref(db, `${config.db_path}/${type}s/${id}`);
      const contentRef = ref(db, `${config.db_path}/content/${id}`);
      const snapshot = await update(dbRef, {
        author: author,
        title: title,
        cover: coverImg,
        type: type,
        id: id,
        date: date,
        description: description,
      });
      const contentSnapshot = await update(contentRef, {
        author: author,
        title: title,
        body: body,
        cover: coverImg,
        type: type,
        id: id,
        date: date,
        description: description,
      });
      navigate(`/${type}s/${id}`);
    }
  };

  return (
    <div className="z-20 mx-auto mt-20 max-w-6xl px-4">
      {title && <h3>Edit Blog {id}</h3>}
      {error && <div>{error + " "}</div>}
      {putError && <div>{putError + " "}</div>}
      {isPending && <PendingBlog />}
      {title && (
        <>
          <MyEditor
            setTitle={setTitle}
            title={title}
            setAuthor={setAuthor}
            author={author}
            setBody={setBody}
            body={body}
            coverImg={coverImg}
            setCoverImg={setCoverImg}
            isPending={putPending}
            handleSubmit={handleSubmit}
            setAsProject={setAsProject}
            asProject={asProject}
            date={date}
            setDate={setDate}
            description={description}
            setDescription={setDescription}
            editorState={"Edit"}
          />
          <div>{title}</div>
          <div className="tiptap my-5">{parser(body)}</div>
          <div>{author}</div>
        </>
      )}
    </div>
  );
}

export default Edit;
