import { Link } from "react-router-dom";

const BlogList = ({ blogs, title, posttype }) => {
  return (
    <>
      <div className="py-8">
        <h1 className="poppins-extrabold mb-8 text-center">{title}</h1>
        {blogs.map((blog) => (
          <Link to={`/${posttype}s/${blog.id}`} key={blog.id}>
            <div className="group mt-2 flex flex-col rounded-md border border-slate-200 bg-stone-50 bg-opacity-30 px-3 py-3 shadow backdrop-blur-lg duration-300 hover:shadow-lg dark:border-stone-700 dark:bg-stone-700/20 md:flex-row">
              <div className="overflow-hidden rounded-md md:max-w-60">
                <img className="" src={blog.cover} />
              </div>
              <div className="my-3 flex flex-col justify-between px-0 align-middle md:my-0 md:px-4">
                <h4 className="poppins-bold text-slate-900 transition-all duration-300 ease-out group-hover:text-red-600 dark:text-slate-50">
                  {blog.title}{" "}
                </h4>
                <p className="poppins-regular py-2 text-sm">
                  {blog.description}
                </p>
                <div className="pt-2">
                  <p className="poppins-bold text-sm uppercase text-neutral-500 dark:text-neutral-400">
                    {blog.date}
                  </p>
                  <p className="poppins-regular text-sm text-neutral-500 dark:text-neutral-400">
                    Written By {blog.author}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default BlogList;
