import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWater,
  faSeedling,
  faDumpsterFire,
  faMapLocationDot,
  faPhone,
  faEnvelopeCircleCheck,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [isGood, setIsGood] = useState(true);
  const [userMessage, setUserMessage] = useState("");

  const submitContact = async (e) => {
    e.preventDefault();
    const now = new Date();
    const sriLankanTime = new Date(now.getTime());
    const formattedDate = sriLankanTime.toISOString().slice(0, 10);
    const formattedTime = sriLankanTime.toTimeString().split(" ")[0];

    const messageText = `
_Date:_ *${formattedDate}*
_Time:_ *${formattedTime} (Server Time)*

_Name:_ *${name}*
_Email:_ \`${email}\`
_Phone:_ \`${phone}\`
_Subject:_ *${subject}*
_Message:_ 
${message}
  `.trim();
    const url = `https://api.telegram.org/bot7378145354:AAEAZKY7Cc2tv2synkZ99kir6ptlYff8VIA/sendMessage`;

    const payload = {
      chat_id: "5612013013",
      text: messageText,
      parse_mode: "Markdown",
    };

    try {
      setWaiting(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setUserMessage(
          "Message sent successfully. Thank you for contacting me!",
        );
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        setIsGood(true);
      } else {
        setUserMessage("Failed to send message. Please try again.");
        setIsGood(false);
      }
    } catch (error) {
      setUserMessage("An error occurred. Please try again.");
      setIsGood(false);
    }
    setWaiting(false);
  };

  return (
    <>
      <section
        id="contact"
        className="relative mx-4 my-8 overflow-hidden rounded-md"
      >
        <div className="flex flex-col-reverse justify-between align-middle md:flex-row md:p-4">
          <div className="poppins-regular mt-8 flex flex-col justify-center align-middle md:w-1/2">
            <FontAwesomeIcon
              className="skill-hover mb-2 text-6xl"
              size="2xl"
              icon={faEnvelopeCircleCheck}
            />
            <p className="poppins-bold text-center uppercase">Email me at</p>
            <a className="text-center" href="mailto:me@upekshaip.com">
              me@upekshaip.com
            </a>
          </div>
          <div className="rounded-md border-2 border-stone-100 shadow-lg backdrop-blur-md dark:border-stone-700 md:w-1/2">
            <div className="p-4 md:p-6">
              <h2 className="poppins-extrabold text-center text-4xl text-red-600">
                Contact
              </h2>
              <form
                className="poppins-regular flex flex-col justify-evenly align-middle"
                onSubmit={submitContact}
              >
                <label className="formLabel">Your Name</label>
                <input
                  className="formInput"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  required
                />

                <label className="formLabel">Email</label>
                <input
                  className="formInput"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

                <label className="formLabel">Phone</label>
                <input
                  className="formInput"
                  type="number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />

                <label className="formLabel">Subject</label>
                <input
                  className="formInput"
                  type="text"
                  required
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                />

                <label className="formLabel">Message</label>
                <textarea
                  className="formInput"
                  rows="5"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                ></textarea>
                <button
                  type="submit"
                  disabled={waiting}
                  className="rounded-md bg-red-600 px-12 py-2 text-center text-base text-white duration-300 hover:bg-red-800 md:pt-2"
                >
                  Send Message
                </button>
                <p
                  className={`poppins-bold my-3 ${isGood ? "text-green-500" : "text-red-500"}`}
                >
                  {userMessage}
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
