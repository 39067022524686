import { useState } from "react";
import { useNavigate } from "react-router-dom";
import parser from "html-react-parser";
import MyEditor from "./items/MyEditor";
import app from "./firebase/FirebaseConfig";
import { getDatabase, ref, push, set } from "firebase/database";
import config from "./firebase/Config";

const Create = () => {
  const [title, setTitle] = useState("");
  const [coverImg, setCoverImg] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [asProject, setAsProject] = useState(false);
  const [body, setBody] = useState(
    "<h1>Hello World!</h1><ul><li>A</li><li>B</li></ul>",
  );
  const [author, setAuthor] = useState("Upeksha Indeewara");
  const [postErr, setPostErr] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const handleBlogSubmit = async (e) => {
    e.preventDefault();

    const submitter = e.nativeEvent.submitter;
    if (submitter && submitter.id === "MyEditorSubmitBtn") {
      console.log(asProject);
      let type = "blog";
      if (asProject) {
        type = "project";
      }
      const blog = { title, body: body, author };
      setIsPending(true);
      const db = getDatabase(app);
      const newDocRef = push(ref(db, `${config.db_path}/${type}s`));
      const contentDocRef = ref(
        db,
        `${config.db_path}/content/${newDocRef.key}`,
      );

      await set(newDocRef, {
        title: title,
        cover: coverImg,
        author: author,
        type: type,
        id: newDocRef.key,
        date: date,
        description: description,
      });

      await set(contentDocRef, {
        title: title,
        cover: coverImg,
        body: body,
        author: author,
        type: type,
        id: newDocRef.key,
        date: date,
        description: description,
      })
        .then(() => {
          setIsPending(false);
          navigate(`/`);
        })
        .catch((error) => {
          console.error("Error setting data in Firebase:", error);
          setIsPending(false);
          setPostErr(error.message);
        });
    }
  };

  return (
    <div className="z-20 mx-auto mt-20 max-w-6xl px-4">
      <h3>Add a New Blog</h3>
      <p>{postErr}</p>
      <MyEditor
        setTitle={setTitle}
        title={title}
        setAuthor={setAuthor}
        author={author}
        setBody={setBody}
        body={body}
        coverImg={coverImg}
        setCoverImg={setCoverImg}
        isPending={isPending}
        handleSubmit={handleBlogSubmit}
        asProject={asProject}
        setAsProject={setAsProject}
        date={date}
        setDate={setDate}
        description={description}
        setDescription={setDescription}
        editorState={"Add"}
      />
      <div>{title}</div>
      <div className="tiptap my-5">{parser(body)}</div>
      <div>{author}</div>
    </div>
  );
};

export default Create;
