import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun, faX } from "@fortawesome/free-solid-svg-icons";
import upekshaip_black from "../../img/upekshaip-black.png";
import upekshaip_white from "../../img/upekshaip-white.png";

export default function NavBar({ isLogged, theme, setTheme }) {
  const [menuExpand, setMenuExpand] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = (e, id) => {
    navigate("/");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      handleClose(e);
    }
  };

  const handleExpand = (e) => {
    // console.log(id);
    if (menuExpand) {
      setMenuExpand(false);
    } else {
      setMenuExpand(true);
    }
  };

  const handleClose = (e) => {
    setMenuExpand(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuExpand(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed top-0 z-50 mx-auto flex w-full bg-slate-50 bg-opacity-60 backdrop-blur dark:bg-stone-950 dark:bg-opacity-50">
      <nav
        ref={menuRef}
        className={`${menuExpand ? "mb-60" : "mb-0"} my-transition flex w-full transform flex-col justify-between px-5 py-3 align-middle transition duration-500 ease-out md:mb-auto md:flex-row`}
      >
        <div className="md:w-1/3">
          <div className="max-w-16">
            <Link to="/" onClick={(e) => handleScroll(e, "home")}>
              <img
                src={theme ? upekshaip_white : upekshaip_black}
                className="max-w-16"
              />
            </Link>
          </div>
        </div>
        <ul
          className={`${menuExpand ? "top-10" : "-top-40"} my-transition poppins-light absolute mx-auto flex w-[90vw] flex-col justify-center align-middle md:relative md:top-auto md:w-1/3 md:flex-row md:gap-7 md:border-purple-400 md:border-opacity-20 md:px-6`}
        >
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "home")}
            className="py-1 text-center transition-colors hover:text-red-500"
          >
            Home
          </Link>
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "about")}
            className="py-1 text-center transition-colors hover:text-red-500"
          >
            About
          </Link>
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "testimonials")}
            className="py-1 text-center transition-colors hover:text-red-500"
          >
            Testimonials
          </Link>
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "contact")}
            className="py-1 text-center transition-colors hover:text-red-500"
          >
            Contact
          </Link>
        </ul>
        <ul
          className={`${menuExpand ? "top-40" : "-top-36"} poppins-light my-transition absolute mt-4 flex w-[90vw] flex-col gap-1.5 align-middle md:relative md:top-auto md:mt-0 md:w-1/3 md:flex-row md:justify-end md:gap-3 md:pt-0`}
        >
          <button className="px-5" onClick={(e) => setTheme(!theme)}>
            {theme ? (
              <FontAwesomeIcon size="xl" icon={faMoon} />
            ) : (
              <FontAwesomeIcon size="xl" icon={faSun} />
            )}
          </button>

          <Link
            to="/projects"
            onClick={handleClose}
            className="z-20 rounded-lg bg-red-600 px-3 py-1.5 text-center text-white duration-300 hover:bg-red-800 md:hover:scale-105"
          >
            Projects
          </Link>
          <Link
            to="/blogs"
            onClick={handleClose}
            className="z-20 rounded-lg bg-red-600 px-3 py-1.5 text-center text-white duration-300 hover:bg-red-800 md:hover:scale-105"
          >
            Blogs
          </Link>
          {isLogged && (
            <>
              <Link
                to="/login"
                onClick={handleClose}
                className="rounded-lg bg-red-600 px-3 py-1.5 text-center text-white duration-300 hover:bg-red-800 md:hover:scale-105"
              >
                Log Out
              </Link>
            </>
          )}
        </ul>
        <button
          onClick={handleExpand}
          className="fixed right-4 top-0 py-5 pl-16 pr-8 md:hidden"
        >
          {menuExpand ? (
            <FontAwesomeIcon icon={faX} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
      </nav>
    </div>
  );
}
