import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faPhone,
  faQuoteLeft,
  faQuoteRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import fiverr_svg from "../../img/fiverr-1.svg";
import freelancer_svg from "../../img/freelancer-1.svg";

const Testimonials = () => {
  return (
    <section className="mx-4 my-16" id="testimonials">
      <div className="mb-6 text-center before:mx-auto before:mb-5 before:block before:h-3 before:w-24 before:rounded-md before:bg-red-600 md:mb-0">
        <h2 className="poppins-extrabold">Testimonials</h2>
        <p className="poppins-regular">
          Here's what some of my clients have to say about working with me.
        </p>
      </div>
      <div className="grid gap-4 sm:p-4 md:grid-cols-12">
        <div className="testimonial-card group col-span-12 lg:col-span-8">
          <h3 className="testimonial-topic poppins-semibold">
            Srapper Project
          </h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            Upekshaip delivered the project on time and also exceeded my
            expectations in terms of quality. Throughout the development
            process, Upekshaip consistently kept me updated on progress and
            promptly addressed any concerns or questions I had. His attention to
            detail and ability to understand my requirements were truly
            impressive. It was evident that he possess deep knowledge in his
            field. His dedication, skill set, and commitment to delivering
            high-quality work are commendable. Thanks Upekshaip
            <FontAwesomeIcon
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.freelancer.com/u/upekshaip?review_context_id=37734705&review_type=project&reviewee_role=freelancer&frm=upekshaip&sb=t"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={freelancer_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/in.svg"
                  className="mr-2 w-5"
                  alt="India"
                ></img>
                • @prashanttsingh3
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="testimonial-card group col-span-12 lg:col-span-4">
          <h3 className="testimonial-topic poppins-semibold">Ref link bot</h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            upekshaip was amazing, So glad i decided to go with him. He is
            flexible, thorough and has excellent communication. Will work with
            him again 110%
            <FontAwesomeIcon
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.freelancer.com/u/upekshaip?review_context_id=37859290&review_type=project&reviewee_role=freelancer&frm=upekshaip&sb=t"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={freelancer_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/us.svg"
                  className="mr-2 w-5"
                  alt="USA"
                ></img>
                • @web3mbiz
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="testimonial-card group col-span-12 lg:col-span-4">
          <h3 className="testimonial-topic poppins-semibold">
            Telegram bot development
          </h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            Developer is very good
            <FontAwesomeIcon
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.fiverr.com/upekshaip"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={fiverr_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/in.svg"
                  className="mr-2 w-5"
                  alt="India"
                ></img>
                • @indrasen1961
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="testimonial-card group col-span-12 lg:col-span-8">
          <h3 className="testimonial-topic poppins-semibold">
            Enterprise-Grade Chatbot Development with Python
          </h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            Upeksha is an exceptional freelancer! He delivered top-notch work
            well before the deadline, staying within budget without compromising
            quality. His proactive communication and problem-solving skills made
            the collaboration seamless. Upeksha's dedication and reliability set
            him apart. I highly recommend him for outstanding results.
            <FontAwesomeIcon
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.freelancer.com/u/upekshaip?review_context_id=37565891&review_type=project&reviewee_role=freelancer&frm=upekshaip&sb=t"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={freelancer_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/in.svg"
                  className="mr-2 w-5"
                  alt="India"
                ></img>
                • @tivia6448
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="testimonial-card group col-span-12 lg:col-span-4">
          <h3 className="testimonial-topic poppins-semibold">
            Automate Chatbot Development with Python
          </h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            Awesome developper for telegram bot ! Delivered in 1 day !
            <FontAwesomeIcon
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.fiverr.com/upekshaip"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={fiverr_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/ma.svg"
                  className="mr-2 w-5"
                  alt="Morocco"
                ></img>
                • @entrapreneur10
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="testimonial-card group col-span-12 lg:col-span-4">
          <h3 className="testimonial-topic poppins-semibold">
            Casino bot development
          </h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            The Best The Best The Best The Best The Best The Best The Best The
            Best The Best The Best The Best
            <FontAwesomeIcon
              //
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.fiverr.com/upekshaip"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={fiverr_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/ma.svg"
                  className="mr-2 w-5"
                  alt="Morocco"
                ></img>
                • @entrapreneur10
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="testimonial-card group col-span-12 lg:col-span-4">
          <h3 className="testimonial-topic poppins-semibold">
            Telegram bot development
          </h3>
          <p className="poppins-regular testimonial-p">
            <FontAwesomeIcon
              className="testimonial-quote mr-4"
              icon={faQuoteLeft}
            />
            Very good work! Anyone who needs a telegram bot come to upekshaip!
            <FontAwesomeIcon
              className="testimonial-quote ml-4"
              icon={faQuoteRight}
            />
          </p>
          <a
            target="_"
            href="https://www.fiverr.com/upekshaip"
            className="poppins-regular mt-4 flex items-center align-middle"
          >
            <img src={fiverr_svg} className="w-12" />
            <div className="ml-2">
              <p className="m-0 flex items-center justify-start p-0 text-sm">
                <img
                  src="https://flagcdn.com/fr.svg"
                  className="mr-2 w-5"
                  alt="France"
                ></img>
                • @serikc
              </p>
              <div>
                <p className="m-0 p-0 text-sm text-yellow-500">
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-0.5" />
                  <FontAwesomeIcon icon={faStar} className="mr-1" />
                  5.0
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
