import square from "../../img/square.png";
import bmc from "../../img/bmc.svg";
import {
  faBrain,
  faCogs,
  faDatabase,
  faDesktop,
  faEnvelope,
  faGlobe,
  faLaptopCode,
  faNetworkWired,
  faProjectDiagram,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialLinks from "./SocialLinks";

const About = () => {
  return (
    <>
      <section className="mx-4 my-8" id="about">
        <div className="mb-6 text-center before:mx-auto before:mb-5 before:block before:h-3 before:w-24 before:rounded-md before:bg-red-600 md:mb-0">
          <h2 className="poppins-extrabold">About Me</h2>
          <p className="poppins-regular poppins-bold uppercase">
            A little about me
          </p>
        </div>
        <div className="grid gap-4 sm:p-4 md:grid-cols-12">
          <div className="rounded-md border-2 border-stone-100 bg-opacity-40 p-4 shadow-lg backdrop-blur-md dark:border-stone-700 md:col-span-4">
            <div className="poppins-regular flex flex-col items-center justify-center">
              <img
                className="w-4/6 rounded-full p-4 brightness-105"
                src={square}
              />
              <div className="text-center">
                <p>Upeksha Indeewara</p>
                <p className="text-sm text-gray-400 dark:text-gray-300/80">
                  @upekshaip
                </p>
                <p className="mt-3">Computer Science Undergrad 👩‍💻</p>
                <p className="">Passionate Coder 💻</p>
                <p className="">Open Source Contributor ✨</p>
                <div className="my-6 flex justify-center">
                  <a
                    className="group h-10"
                    target="_"
                    href="https://buymeacoffee.com/upekshaip"
                  >
                    <img
                      className="h-10 transition-all duration-300 ease-out group-hover:scale-105"
                      src={bmc}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center rounded-md border-2 border-stone-100 bg-opacity-40 p-4 shadow-lg backdrop-blur-md dark:border-stone-700 md:col-span-8">
            <div className="p-4">
              <h3 className="poppins-extrabold uppercase">
                Let me introduce myself,
              </h3>
              <p className="poppins-regular mt-4 text-sm">
                As a dedicated Computer Science undergraduate at NSBM Green
                University, I bring a wealth of expertise in GUI application
                development, autoscripting, web design, mobile app development,
                and cloud-based automation. My proficiency in Python, PHP, C,
                HTML, CSS, JavaScript, Java, JSP, C++, Qt5, Node.js, React, Next
                JS, Firebase, Google Cloud Console, TailwindCSS, Bootstrap, API
                development, chatbot development, website development,
                client-side and server-side development, and DNS configurations
                enables me to streamline processes and create efficient,
                innovative solutions. Throughout my academic journey, I have
                independently developed a comprehensive LMS platform in Sri
                Lanka and crafted numerous web applications for university
                projects, consistently applying my skills to real-world
                scenarios.
              </p>
              <p className="poppins-regular mb-12 mt-6 text-sm">
                In my free time, I actively seek opportunities to take on new
                projects and bring innovative ideas to life. Whether you're
                looking for assistance with a specific project or need
                comprehensive development services, I'm ready to collaborate and
                create something incredible together. As a freelancer, I'm
                available on platforms like Freelancer and Fiverr, where you can
                hire me to leverage my diverse skill set. Let's build something
                amazing and push the boundaries of what's possible with
                technology! 🚀
              </p>
              <SocialLinks />
            </div>
          </div>
          <div className="rounded-md bg-opacity-40 backdrop-blur-md md:col-span-12">
            <h3 className="poppins-extrabold my-4 text-center uppercase">
              My Development <span className="text-red-600">Skills</span>
            </h3>
            <div className="poppins-regular grid gap-4 text-sm md:grid-cols-12">
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faProjectDiagram}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  API Development
                </h4>
                <p>
                  Crafting robust APIs for seamless software communication,
                  enabling efficient data exchange between systems.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faDesktop}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  Application (GUI) Development
                </h4>
                <p>
                  Designing intuitive and visually appealing user interfaces for
                  applications to enhance user experience.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faGlobe}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  Website Development
                </h4>
                <p>
                  Building responsive, interactive websites from scratch,
                  ensuring smooth and engaging user experiences.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faRobot}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  Chatbot Development
                </h4>
                <p>
                  Creating intelligent chatbots for automated, natural
                  interactions across various messaging platforms.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faLaptopCode}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  Client-Side and Server-Side Development
                </h4>
                <p>
                  Developing and managing both client-side and server-side logic
                  for high-performance, full-stack web applications.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faNetworkWired}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  DNS Configurations
                </h4>
                <p>
                  Setting up and managing DNS records to ensure your website is
                  easily accessible on the internet.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faDatabase}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  Database Configuration and Integration
                </h4>
                <p>
                  Efficiently setting up and managing databases, ensuring smooth
                  data storage and retrieval for your applications.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faBrain}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  AI Implementation
                </h4>
                <p>
                  Applying advanced AI techniques to develop smart, learning
                  systems that perform complex tasks autonomously.
                </p>
              </div>
              <div className="skill-card group">
                <FontAwesomeIcon
                  className="skill-hover mb-5"
                  size="2xl"
                  icon={faCogs}
                />
                <h4 className="poppins-bold skill-hover mb-4">
                  CMS Development and Integration
                </h4>
                <p>
                  Building and integrating powerful content management systems
                  to streamline your content creation and management processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
