import hero_black from "../../img/upekshaip-black.png";
import hero_white from "../../img/upekshaip-white.png";
import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";

const Hero = ({ theme }) => {
  const handleScroll = (e, id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section
        id="home"
        className="relative mx-8 flex min-h-screen flex-col-reverse items-center justify-center gap-4 rounded-md p-4 backdrop-blur-md md:flex-row"
      >
        <div className="mb-0 text-center md:w-1/2 md:text-start">
          <h1 className="poppins-extrabold my-4 mb-0 uppercase">
            <span className="xl:text-7xl">Transforming </span>
          </h1>
          <h1 className="poppins-extrabold mb-6 mt-0 uppercase xl:text-6xl">
            <span className="underline decoration-red-600">Ideas</span> into
            <span className="text-red-600"> Reality</span>
          </h1>
          <p className="poppins-regular mb-8">
            As a passionate and skilled developer, I transform ideas into
            reality by blending creativity and technical proficiency. Explore my
            portfolio to see how I deliver exceptional digital experiences that
            meet the unique needs of each project.
          </p>
          <Link
            to=""
            onClick={(e) => handleScroll(e, "about")}
            className="poppins-regular z-20 rounded-md bg-red-600 px-8 py-2 text-center text-white duration-300 hover:bg-red-800 md:pt-2 md:hover:scale-105"
          >
            About Me
          </Link>
          <div className="bottom-5 hidden md:absolute md:bottom-10 md:block">
            <SocialLinks className="" />
          </div>
        </div>
        <div className="w-2/4 md:w-1/3">
          <img src={theme ? hero_white : hero_black} className="p-6 xl:p-16" />
        </div>
      </section>
    </>
  );
};

export default Hero;
