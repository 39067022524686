// import "./App.css";
import BlogDetails from "./components/BlogDetails";
import Create from "./components/Create";
import NavBar from "./components/items/NavBar";
import Blogs from "./components/Blogs";
import Edit from "./components/Edit";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import useCheckLogin from "./components/firebase/useCheckLogin";
import Projects from "./components/Projects";
import Footer from "./components/items/Footer";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
// import Custom from "./components/Custom";

export default function App() {
  const { isLogged, setIsLogged } = useCheckLogin();
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? JSON.parse(savedTheme) : false;
  }); // false: light

  useEffect(() => {
    if (theme) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", JSON.stringify(theme));
  }, [theme]);

  return (
    <Router>
      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>upekshaip | Computer Science Undergrad</title>
          <meta
            name="description"
            content="Welcome to upekshaip's portfolio website."
          />
          <meta
            name="keywords"
            content="Upeksha, Indeewara, portfolio, projects, blogs"
          />
          <meta name="author" content="upekshaip" />
          <meta property="og:title" content="upekshaip | Home" />
          <meta
            property="og:description"
            content="Welcome to upekshaip's portfolio website."
          />
          <meta property="og:url" content="https://upekshaip.com/" />
          <meta property="og:type" content="website" />
        </Helmet>
        <NavBar isLogged={isLogged} theme={theme} setTheme={setTheme} />
        <main className="mx-auto flex min-h-screen flex-col">
          <div className="">
            <Routes>
              <Route exact path="/" element={<Home theme={theme} />}></Route>
              <Route
                exact
                path="/blogs"
                element={<Blogs isLogged={isLogged} />}
              ></Route>
              <Route
                exact
                path="/projects"
                element={<Projects isLogged={isLogged} />}
              ></Route>
              {/* <Route exact path="/test" element={<Custom />}></Route> */}
              <Route
                path="/login"
                element={
                  <Login isLogged={isLogged} setIsLogged={setIsLogged} />
                }
              ></Route>
              <Route
                path="/blogs/:id"
                element={<BlogDetails posttype={"blog"} isLogged={isLogged} />}
              ></Route>
              <Route
                path="/projects/:id"
                element={
                  <BlogDetails posttype={"project"} isLogged={isLogged} />
                }
              ></Route>
              {isLogged && (
                <>
                  <Route exact path="/create" element={<Create />}></Route>
                  <Route
                    path="/edit/blog/:id"
                    element={<Edit posttype={"blog"} isLogged={isLogged} />}
                  ></Route>
                  <Route
                    path="/edit/project/:id"
                    element={<Edit posttype={"project"} isLogged={isLogged} />}
                  ></Route>
                </>
              )}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer theme={theme} className="mt-auto" />
        </main>
      </div>
    </Router>
  );
}
