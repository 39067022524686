import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faInstagram,
  faLinkedin,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

const SocialLinks = () => {
  return (
    <div className="w-48 text-left">
      <div className="flex items-center justify-between">
        <a href="https://github.com/upekshaip" target="_">
          <FontAwesomeIcon
            className="transit text-3xl duration-300 ease-out hover:text-red-600"
            size="xl"
            icon={faGithub}
          />
        </a>
        <a href="https://www.linkedin.com/in/upekshaip" target="_">
          <FontAwesomeIcon
            className="transit text-3xl duration-300 ease-out hover:text-red-600"
            size="xl"
            icon={faLinkedin}
          />
        </a>
        <a href="https://t.me/upekshaip" target="_">
          <FontAwesomeIcon
            className="transit text-3xl duration-300 ease-out hover:text-red-600"
            size="xl"
            icon={faTelegram}
          />
        </a>
        <a href="mailto:me@upekshaip.com">
          <FontAwesomeIcon
            className="transit text-3xl duration-300 ease-out hover:text-red-600"
            size="xl"
            icon={faEnvelope}
          />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
