import { Link, useNavigate } from "react-router-dom";
import "../styles/body.css";
import Testimonials from "./home/Testimonials";
import Education from "./home/Education";
import About from "./home/About";
import Hero from "./home/Hero";
import Contact from "./home/Contact";

const Home = ({ theme }) => {
  const navigate = useNavigate();
  const handleScroll = (id) => {
    navigate("/");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Hero theme={theme} />
      <About />
      <Education />
      <Testimonials />
      <Contact />
    </>
  );
};

export default Home;
