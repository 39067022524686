import { auth } from "./firebase/FirebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";

const Login = ({ isLogged, setIsLogged }) => {
  const [username, setUsername] = useState(false);
  const [password, setPassword] = useState(false);
  const [error, setError] = useState(false);
  const [logUsername, setLogUsername] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, username, password);
      setIsLogged(true);
      setError("Login success!");
    } catch (error) {
      setIsLogged(false);
      setError(error.message);
    }
    setUsername("");
    setPassword("");
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();

      setIsLogged(false);
      setLogUsername(null);
      setError("Logout success!");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="z-20 mx-auto mt-12 max-w-6xl px-4">
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-medium leading-6">
                Email address
              </label>
              <div className="mt-2">
                <input
                  name="username"
                  type="text"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  required
                  className="formInput"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium leading-6">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  name="password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                  className="formInput"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
          {error && <div className="py-2">{error}</div>}

          {isLogged && (
            <div>
              <button
                onClick={handleLogout}
                className="mt-5 flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
              >
                Log Out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
