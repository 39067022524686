import hero_black from "../../img/upekshaip-black.png";
import hero_white from "../../img/upekshaip-white.png";
import { Link } from "react-router-dom";

const Footer = ({ theme }) => {
  return (
    <>
      <footer className="mt-auto w-full border-t-2 border-t-stone-200 py-2 dark:border-t-stone-700">
        <div className="container relative mx-auto flex flex-col items-center justify-between md:flex-row md:py-5">
          <div className="mb-4 flex items-center md:mb-0">
            <span className="poppins-extrabold text-xl">upekshaip.com</span>
          </div>
          <div className="poppins-regular relative mb-4 w-full text-center md:absolute md:mb-0 md:pt-3">
            <p className="">© 2024 upekshaip. All Rights Reserved.</p>
            <Link to="/" className="poppins-regular z-10 text-sm">
              Developed by upekshaip
            </Link>
          </div>
          <div className="z-0 flex items-center justify-center">
            <Link to="/" className="ml-2">
              <img
                src={theme ? hero_white : hero_black}
                alt="upekshaip logo"
                className="z-20 w-16"
              />
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
