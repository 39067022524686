import BlogList from "./items/BlogList";
import useFetch from "./useFetch";
import PendingBlog from "./items/PendingBlog";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ResourceError from "./ResourceError";
import tw_gradient from "../img/docs-dark.avif";
import config from "./firebase/Config";
import img from "../img/upekshaip.png";

const Projects = ({ isLogged }) => {
  const {
    data: projects,
    isPending: projPending,
    error: projError,
  } = useFetch(`${config.db_path}/projects`);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>upekshaip | Projects</title>
        <meta
          name="description"
          content="Discover upekshaip's featured projects and accomplishments."
        />
        <meta
          name="keywords"
          content="Upeksha, Indeewara, projects, portfolio, achievements"
        />
        <meta name="author" content="upekshaip" />
        <meta property="og:title" content="upekshaip | Projects" />
        <meta
          property="og:description"
          content="Discover upekshaip's featured projects and accomplishments."
        />
        <meta property="og:url" content="https://upekshaip.com/projects" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={img} />
      </Helmet>
      <div className="px-4">
        <div className="mx-auto mt-20 max-w-6xl">
          <img
            alt=""
            src={tw_gradient}
            className="absolute top-0 -z-10 w-5/6 contrast-150 hue-rotate-90"
          />
          {isLogged && (
            <Link
              to="/create"
              className="absolute right-0 top-3 rounded-full bg-blue-500 px-4 py-1.5 text-center text-white duration-300 hover:bg-blue-800 md:pt-1.5 md:hover:scale-105"
            >
              Create
            </Link>
          )}
          {projPending && <PendingBlog />}
          {projError && <ResourceError />}
          {/* {error && <div>{error}</div>} */}

          {projects && (
            <BlogList
              blogs={Object.values(projects)}
              title={"My Projects"}
              posttype={"project"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Projects;
