const Education = () => {
  return (
    <section className="poppins-regular my-8">
      <div className="mx-auto max-w-5xl py-8 md:px-12 lg:px-36">
        <div className="mx-4 grid gap-4 sm:grid-cols-12">
          <div className="col-span-12 sm:col-span-3">
            <div className="mb-0 text-center before:mx-auto before:mb-5 before:block before:h-3 before:w-24 before:rounded-md before:bg-red-600 sm:text-left sm:before:mx-0">
              <h3 className="poppins-extrabold text-3xl">Education</h3>
              <span className="poppins-bold text-sm uppercase tracking-wider text-gray-600 dark:text-neutral-300">
                Here is my timeline
              </span>
            </div>
          </div>
          <div className="relative col-span-12 space-y-6 sm:col-span-9 sm:px-4">
            <div className="relative col-span-12 before:bg-gray-300 dark:before:bg-stone-700 sm:col-span-8 sm:space-y-8 sm:px-4 sm:before:absolute sm:before:-left-3 sm:before:bottom-0 sm:before:top-2 sm:before:w-0.5">
              <div className="mb-4 flex flex-col rounded-md border border-neutral-50 p-5 shadow-md before:bg-red-600 dark:border-neutral-600 sm:relative sm:border-none sm:p-0 sm:shadow-none sm:before:absolute sm:before:left-[-35px] sm:before:top-2 sm:before:z-[1] sm:before:h-4 sm:before:w-4 sm:before:rounded-full">
                <h3 className="poppins-semibold text-xl tracking-wide">
                  Computer Science Bachelor Degree
                </h3>
                <time className="text-xs uppercase tracking-wide text-gray-600 dark:text-neutral-300">
                  September 2022 - Present
                </time>
                <p className="mt-3">
                  Currently pursuing a Bachelor of Computer Science.
                </p>
              </div>
              <div className="mb-4 flex flex-col rounded-md border border-neutral-50 p-5 shadow-md before:bg-red-600 dark:border-neutral-600 sm:relative sm:border-none sm:p-0 sm:shadow-none sm:before:absolute sm:before:left-[-35px] sm:before:top-2 sm:before:z-[1] sm:before:h-4 sm:before:w-4 sm:before:rounded-full">
                <h3 className="poppins-semibold text-xl tracking-wide">
                  Bandaranayake College
                </h3>
                <time className="text-xs uppercase tracking-wide text-gray-600 dark:text-neutral-300">
                  January 2018 - June 2020
                </time>
                <p className="mt-3">Completed Advanced Level examinations.</p>
              </div>
              <div className="mb-4 flex flex-col rounded-md border border-neutral-50 p-5 shadow-md before:bg-red-600 dark:border-neutral-600 sm:relative sm:border-none sm:p-0 sm:shadow-none sm:before:absolute sm:before:left-[-35px] sm:before:top-2 sm:before:z-[1] sm:before:h-4 sm:before:w-4 sm:before:rounded-full">
                <h3 className="poppins-semibold text-xl tracking-wide">
                  Bandaranayake College
                </h3>
                <time className="text-xs uppercase tracking-wide text-gray-600 dark:text-neutral-300">
                  January 2012 - December 2017
                </time>
                <p className="mt-3">Completed Ordinary Level examinations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
