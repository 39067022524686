import { Link } from "react-router-dom";

const ResourceError = () => {
  return (
    <>
      <div className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          {/* <p className="text-7xl font-semibold text-red-600">Sorry!</p> */}
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-slate-200">
            Resources Not Found
          </h1>
          <p className="mt-2 text-base leading-7 text-gray-600 dark:text-slate-300">
            Sorry, Cannot Fetch data from the resources.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Go back home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceError;
